import moment from 'moment'
/**
 * @author chuzhixin 1204505056@qq.com
 * @description 格式化时间
 * @param time
 * @param cFormat
 * @returns {string|null}
 */
export function parseTime(time, cFormat) {
  if (arguments.length === 0 || typeof time === 'undefined') {
    return null
  }
  const format = cFormat || '{y}-{m}-{d} {h}:{i}:{s}'
  let date
  if (typeof time === 'object') {
    date = time
  } else {
    if (typeof time === 'string' && /^[0-9]+$/.test(time)) {
      time = parseInt(time)
    }
    if (typeof time === 'number' && time.toString().length === 10) {
      time = time * 1000
    }
    date = new Date(time)
  }
  const formatObj = {
    y: date.getFullYear(),
    m: date.getMonth() + 1,
    d: date.getDate(),
    h: date.getHours(),
    i: date.getMinutes(),
    s: date.getSeconds(),
    a: date.getDay(),
  }
  return format.replace(/{([ymdhisa])+}/g, (result, key) => {
    let value = formatObj[key]
    if (key === 'a') {
      return ['日', '一', '二', '三', '四', '五', '六'][value]
    }
    if (result.length > 0 && value < 10) {
      value = '0' + value
    }
    return value || 0
  })
}

/**
 * @author chuzhixin 1204505056@qq.com
 * @description 格式化时间
 * @param time
 * @param option
 * @returns {string}
 */
export function formatTime(time, option) {
  if (('' + time).length === 10) {
    time = parseInt(time) * 1000
  } else {
    time = +time
  }
  const d = new Date(time)
  const now = Date.now()

  const diff = (now - d) / 1000

  if (diff < 30) {
    return '刚刚'
  } else if (diff < 3600) {
    // less 1 hour
    return Math.ceil(diff / 60) + '分钟前'
  } else if (diff < 3600 * 24) {
    return Math.ceil(diff / 3600) + '小时前'
  } else if (diff < 3600 * 24 * 2) {
    return '1天前'
  }
  if (option) {
    return parseTime(time, option)
  } else {
    return (
      d.getMonth() +
      1 +
      '月' +
      d.getDate() +
      '日' +
      d.getHours() +
      '时' +
      d.getMinutes() +
      '分'
    )
  }
}

/**
 * @author cwhuang
 * @description 計算兩日期之間天數
 * @param time1
 * @param time2
 * @returns {number}
 */
export function dateDiff(time1, time2 = today()) {
  let date1
  if (typeof time1 === 'object') {
    date1 = time1
  } else {
    if (typeof time1 === 'string' && /^[0-9]+$/.test(time1)) {
      time1 = parseInt(time1)
    }
    if (typeof time1 === 'number' && time1.toString().length === 10) {
      time1 = time1 * 1000
    }
    date1 = new Date(time1)
  }

  let date2
  if (typeof time2 === 'object') {
    date2 = time2
  } else {
    if (typeof time2 === 'string' && /^[0-9]+$/.test(time2)) {
      time2 = parseInt(time2)
    }
    if (typeof time2 === 'number' && time2.toString().length === 10) {
      time2 = time2 * 1000
    }
    date2 = new Date(time2)
  }

  return parseInt(Math.abs(date1 - date2) / 1000 / 60 / 60 / 24)
}

function today() {
  return new Date().toISOString()
}

/**
 * @author cwhuang
 * @description 取得加減幾天後日期
 * @param {Number} days 加減幾日
 * @param {String} cFormat 回傳格式預設YYYY/MM/DD
 * @returns {String}
 */
export function dateAdd(days = 0, cFormat) {
  const format = cFormat || 'YYYY/MM/DD'
  return moment(
    new Date(new Date().setDate(new Date().getDate() + days))
      .toISOString()
      .slice(0, 10),
    format
  )
}

/**
 * @author cwhuang
 * @description Rounds a float
 * @param {number} value
 * @param {number|null} precision
 * @returns {number}
 */
export function round(value, precision = 0) {
  return isNaN(value) ? '' : value.toFixed(precision)
}

/**
 * @author cwhuang
 * @description 數字加入千分位
 * @param {number} value
 * @returns {string}}
 */
export function numberComma(value) {
  return value.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ',')
}

/**
 * @author chuzhixin 1204505056@qq.com
 * @description 将url请求参数转为json格式
 * @param url
 * @returns {{}|any}
 */
export function paramObj(url) {
  const search = url.split('?')[1]
  if (!search) {
    return {}
  }
  return JSON.parse(
    '{"' +
      decodeURIComponent(search)
        .replace(/"/g, '\\"')
        .replace(/&/g, '","')
        .replace(/=/g, '":"')
        .replace(/\+/g, ' ') +
      '"}'
  )
}

/**
 * @author chuzhixin 1204505056@qq.com
 * @description 父子关系的数组转换成树形结构数据
 * @param data
 * @returns {*}
 */
export function translateDataToTree(data) {
  const parent = data.filter(
    (value) => value.parentId === 'undefined' || value.parentId == null
  )
  const children = data.filter(
    (value) => value.parentId !== 'undefined' && value.parentId != null
  )
  const translator = (parent, children) => {
    parent.forEach((parent) => {
      children.forEach((current, index) => {
        if (current.parentId === parent.id) {
          const temp = JSON.parse(JSON.stringify(children))
          temp.splice(index, 1)
          translator([current], temp)
          typeof parent.children !== 'undefined'
            ? parent.children.push(current)
            : (parent.children = [current])
        }
      })
    })
  }
  translator(parent, children)
  return parent
}

/**
 * @author chuzhixin 1204505056@qq.com
 * @description 树形结构数据转换成父子关系的数组
 * @param data
 * @returns {[]}
 */
export function translateTreeToData(data) {
  const result = []
  data.forEach((item) => {
    const loop = (data) => {
      result.push({
        id: data.id,
        name: data.name,
        parentId: data.parentId,
      })
      const child = data.children
      if (child) {
        for (let i = 0; i < child.length; i++) {
          loop(child[i])
        }
      }
    }
    loop(item)
  })
  return result
}

/**
 * @author chuzhixin 1204505056@qq.com
 * @description 10位时间戳转换
 * @param time
 * @returns {string}
 */
export function tenBitTimestamp(time) {
  const date = new Date(time * 1000)
  const y = date.getFullYear()
  let m = date.getMonth() + 1
  m = m < 10 ? '' + m : m
  let d = date.getDate()
  d = d < 10 ? '' + d : d
  let h = date.getHours()
  h = h < 10 ? '0' + h : h
  let minute = date.getMinutes()
  let second = date.getSeconds()
  minute = minute < 10 ? '0' + minute : minute
  second = second < 10 ? '0' + second : second
  return y + '年' + m + '月' + d + '日 ' + h + ':' + minute + ':' + second //组合
}

/**
 * @author chuzhixin 1204505056@qq.com
 * @description 13位时间戳转换
 * @param time
 * @returns {string}
 */
export function thirteenBitTimestamp(time) {
  const date = new Date(time / 1)
  const y = date.getFullYear()
  let m = date.getMonth() + 1
  m = m < 10 ? '' + m : m
  let d = date.getDate()
  d = d < 10 ? '' + d : d
  let h = date.getHours()
  h = h < 10 ? '0' + h : h
  let minute = date.getMinutes()
  let second = date.getSeconds()
  minute = minute < 10 ? '0' + minute : minute
  second = second < 10 ? '0' + second : second
  return y + '年' + m + '月' + d + '日 ' + h + ':' + minute + ':' + second //组合
}

/**
 * @author chuzhixin 1204505056@qq.com
 * @description 获取随机id
 * @param length
 * @returns {string}
 */
export function uuid(length = 32) {
  const num = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ1234567890'
  let str = ''
  for (let i = 0; i < length; i++) {
    str += num.charAt(Math.floor(Math.random() * num.length))
  }
  return str
}

/**
 * @author chuzhixin 1204505056@qq.com
 * @description m到n的随机数
 * @param m
 * @param n
 * @returns {number}
 */
export function random(m, n) {
  return Math.floor(Math.random() * (m - n) + n)
}

/**
 * @author chuzhixin 1204505056@qq.com
 * @description addEventListener
 * @type {function(...[*]=)}
 */
export const on = (function () {
  return function (element, event, handler, useCapture = false) {
    if (element && event && handler) {
      element.addEventListener(event, handler, useCapture)
    }
  }
})()

/**
 * @author chuzhixin 1204505056@qq.com
 * @description removeEventListener
 * @type {function(...[*]=)}
 */
export const off = (function () {
  return function (element, event, handler, useCapture = false) {
    if (element && event) {
      element.removeEventListener(event, handler, useCapture)
    }
  }
})()

/**
 * @author cwhuang
 * @description 回傳用餐狀態代號轉文字
 * @param {string} str
 * @returns {string}
 */
export function mealString(str = '') {
  str = str.toLowerCase() || ''
  let mealName = ''
  switch (str) {
    case 'fasting':
    case 'Fasting':
      mealName = '空腹'
      break
    case 'before meal':
    case 'before_meal':
    case 'preprandial':
      mealName = '餐前'
      break
    case 'after meal':
    case 'after_meal':
    case 'postprandial':
      mealName = '餐後'
      break
    default:
      mealName = ''
      break
  }
  return mealName
}

/**
 * @author cwhuang
 * @description 服務狀態代號轉文字
 * @param {Number} code
 * @returns {string}
 */
export function serviceStatusString(code) {
  let serviceName = ''
  switch (code) {
    case 0:
      serviceName = '已建立'
      break
    case 1:
      serviceName = '進行中'
      break
    case 2:
      serviceName = '暫停中'
      break
    case 3:
      serviceName = '已結束'
      break
    case 4:
      serviceName = '已停止'
      break
    default:
      serviceName = '查無此狀態'
      break
  }
  return serviceName
}

/**
 * @author cwhuang
 * @description 量測項目代號轉文字
 * @param {string} str
 * @returns {string}
 */
export function measureString(str) {
  let Name = ''
  switch (str) {
    case 'bloodPressure':
      Name = '血壓'
      break
    case 'weight':
      Name = '身體組成'
      break
    case 'SpO2':
      Name = '血氧'
      break
    case 'bodyTemperature':
      Name = '體溫'
      break
    case 'bloodGlucose':
      Name = '血糖'
      break
    default:
      Name = '查無此項目'
      break
  }
  return Name
}

/**
 * @author cwhuang
 * @description 個案搜尋條件轉文字
 * @param {object} object
 * @returns {string}
 */
export function CaseRulesString(rules) {
  const StringArr = []
  rules.forEach((item) => {
    let content = ''
    // 集合運算轉文字
    let setsName = ''
    switch (item.sets) {
      case 'and':
        setsName = '並且 '
        break
      case 'or':
        setsName = '或者 '
        break
    }
    content += ' ' + setsName

    // 項目轉文字
    let subjectName = ''
    switch (item.subject) {
      case 'systolic':
        subjectName = '平均收縮壓(毫米汞柱)'
        break
      case 'diastolic':
        subjectName = '平均舒張壓(毫米汞柱)'
        break
      case 'pulse':
        subjectName = '平均脈搏(次/分鐘)'
        break
      case 'pulsePressureDifference':
        subjectName = '平均脈壓差(毫米汞柱)'
        break
      case 'arr':
        subjectName = '心律不整次數'
        break
      case 'weight':
        subjectName = '平均體重'
        break
      case 'bmi':
        subjectName = '平均BMI'
        break
      case 'bmr':
        subjectName = '平均基礎代謝率'
        break
      case 'body_fat':
        subjectName = '平均體脂率'
        break
      case 'muscle_mass':
        subjectName = '平均肌肉率'
        break
      case 'visceral_fat':
        subjectName = '平均內臟脂肪'
        break
      case 'spo2_lowest':
        subjectName = '平均血氧(最低%)'
        break
      case 'temperature':
        subjectName = '平均體溫(度)'
        break
      case 'bloodGlucose':
        subjectName = '平均血糖(毫克/分升)'
        break
      case 'meal':
        subjectName = '用餐時間'
        break
    }
    content += subjectName

    // 邏輯轉文字
    let predicateName = ''
    switch (item.predicate) {
      case 'gt':
        predicateName = '大於'
        break
      case 'ge':
        predicateName = '大於等於'
        break
      case 'eq':
        predicateName = '等於'
        break
      case 'lt':
        predicateName = '小於'
        break
      case 'le':
        predicateName = '小於等於'
        break
      case 'bw':
        predicateName = '介於'
        break
      case 'nbw':
        predicateName = '不介於'
        break
    }
    content += ' ' + predicateName

    // 血糖用餐時間因爲是清單，所以另外顯示
    if (item.subject != 'meal') {
      // 數值
      switch (item.predicate) {
        case 'bw':
        case 'nbw':
          content += ' ' + `${item.component[0]}～${item.component[1]}`
          break
        default:
          content += ' ' + item.component
          break
      }
    } else {
      switch (item.component) {
        case 'fasting':
          content += ' 空腹'
          break
        case 'before_meal':
          content += ' 餐前'
          break
        case 'after_meal':
          content += ' 餐後'
          break
      }
    }

    StringArr.push(content)
  })

  return StringArr.join(' ')
}

/**
 * @author cwhuang
 * @description 卡號隱藏隱碼
 * @param {string} str
 * @returns {string}
 */
export function cardNoHidden(str) {
  return str.substring(0, 10)
}

/**
 * @author cwhuang
 * @description 量測記錄計算平均值
 * @param {Array} data 量測資料
 * @param {string} item 計算項目
 * @returns {Number}
 */
export function measurementAvg(data, item, decimal = 0) {
  if (data.length === 0) return Infinity
  const total = data.reduce((acc, c) => acc + c.measurement[item], 0)
  return parseFloat(round(total / data.length, decimal))
}

/**
 * 已string作爲key取得object值
 * 使用範例：Object.byString(someObj, 'part3[0].name');
 */
Object.byString = function (o, s) {
  s = s.replace(/\[(\w+)\]/g, '.$1') // convert indexes to properties
  s = s.replace(/^\./, '') // strip a leading dot
  var a = s.split('.')
  for (var i = 0, n = a.length; i < n; ++i) {
    var k = a[i]
    if (k in o) {
      o = o[k]
    } else {
      return
    }
  }
  return o
}

/**
 * @author cwhuang
 * @description 取得表格刻度資料
 * @param {object} data 量測資料範圍{min,max,divided(分幾份)}
 * @returns {object}
 */
// export function getChartYAxisRange({ min, max, divided = 5 }) {
//   const diff = Math.abs(max - min) / divided
//   const gap = Math.ceil(diff / divided) * divided
//   const axisMin = Math.floor(min / gap) * gap
//   const axisMax = Math.ceil(max / gap) * gap
//   const axisInterval = (axisMax - axisMin) / divided
//   return { min: axisMin, max: axisMax, interval: axisInterval }
// }
export function getChartYAxisRange({ min, max, divided = 5 }) {
  const axisMin = Math.floor(min / divided) * divided
  let axisMax = Math.ceil(max / divided) * divided
  let axisInterval = 5
  if ((axisMax - axisMin) / divided < 5) {
    axisInterval = Math.ceil((axisMax - axisMin) / divided)
  } else {
    axisInterval = Math.ceil((axisMax - axisMin) / divided / divided) * 5
  }
  axisMax = axisMin + axisInterval * divided
  return { min: axisMin, max: axisMax, interval: axisInterval }
}

/**
 * 取得開始日期與結束日期之間的所有日期陣列
 * @param {String} begin
 * @param {String} end
 * @returns {Array}
 */
export function getAllDates(begin, end) {
  //返回 日期的数组 如 ['2020-07-10','2020-07-11']
  var arr = []
  var ab = begin.split('-')
  var ae = end.split('-')
  var db = new Date(ab[0], ab[1] - 1, ab[2])
  var de = new Date(ae[0], ae[1] - 1, ae[2])
  var unixDb = db.getTime() - 24 * 60 * 60 * 1000
  var unixDe = de.getTime() - 24 * 60 * 60 * 1000
  for (var k = unixDb; k <= unixDe; ) {
    k = k + 24 * 60 * 60 * 1000
    arr.push(parseTime(new Date(parseInt(k)), '{y}-{m}-{d}'))
  }
  return arr
}

/**
 * 取得指定日期的當週第一天
 * @param {String} date
 * @returns {String}
 */
export function getStartOfWeek(date) {
  const curr = new Date(date)
  const first = curr.getDate() - curr.getDay()
  const firstDate = new Date(curr.setDate(first))

  const firstday = new Date(
    firstDate.getFullYear(),
    firstDate.getMonth(),
    firstDate.getDate()
  ).getTime()

  return firstday
}

/**
 * 取得指定日期的當週最後一天
 * @param {String} date
 * @returns {String}
 */
export function getEndOfWeek(date) {
  const curr = new Date(date)
  const first = curr.getDate() + (7 - curr.getDay())
  const firstDate = new Date(curr.setDate(first))

  const firstday = new Date(
    firstDate.getFullYear(),
    firstDate.getMonth(),
    firstDate.getDate(),
    23,
    59,
    59
  ).getTime()

  return firstday
}

/**
 * 取得紀錄狀態數量(良好、注意、異常)
 * @param {Array} data 量測資料
 * @param {string} itemName 計算項目
 * @returns {object}
 */
export function getRecordStatus(data, itemName) {
  const itemNameLower = itemName.toLowerCase()
  let items = []
  // 血糖數值需要先篩選過再放進來，所以不用在此在篩一次
  if (!['fasting', 'before meal', 'after meal'].includes(itemNameLower)) {
    items = data.map((item) => item.measurement[itemName])
  } else {
    items = data
  }

  let good = 0, // 良好
    attention = 0, // 注意
    abnormal = 0 //異常
  switch (itemNameLower) {
    case 'systolic': // 血壓：收縮壓
      items.forEach((item) => {
        if (item >= 180) {
          abnormal++
        } else if ((item >= 30 && item <= 89) || (item >= 140 && item <= 179)) {
          attention++
        } else {
          good++
        }
      })
      break
    case 'diastolic': // 血壓：舒張壓
      items.forEach((item) => {
        if (item >= 110) {
          abnormal++
        } else if ((item >= 30 && item <= 59) || (item >= 90 && item <= 109)) {
          attention++
        } else {
          good++
        }
      })
      break
    case 'pulsepressuredifference': // 血壓：脈壓差
      items.forEach((item) => {
        if (item >= 60 || item <= 20) {
          abnormal++
        } else {
          good++
        }
      })
      break
    case 'pulse': // 血壓：脈膊
      items.forEach((item) => {
        if (item >= 150) {
          abnormal++
        } else if ((item >= 40 && item <= 59) || (item >= 101 && item <= 149)) {
          attention++
        } else {
          good++
        }
      })
      break
    case 'fasting': // 血糖：空腹
    case 'before meal': // 血糖：餐前
      items.forEach((item) => {
        if (item >= 126) {
          abnormal++
        } else if ((item >= 100 && item <= 125) || item <= 69) {
          attention++
        } else {
          good++
        }
      })
      break
    case 'after meal': // 血糖：餐後
      items.forEach((item) => {
        if (item >= 200) {
          abnormal++
        } else if ((item >= 181 && item <= 199) || item <= 69) {
          attention++
        } else {
          good++
        }
      })
      break
    case 'spo2lowest': // 血氧
      items.forEach((item) => {
        if (item <= 89) {
          abnormal++
        } else if (item <= 94) {
          attention++
        } else {
          good++
        }
      })
      break
  }
  return { good, attention, abnormal }
}
